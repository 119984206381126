import * as React from 'react'
import AudioChecker from './audioChecker'

interface Iprops{

}

interface Istate{
    mediaStream:MediaStream|null
}

class Main extends React.Component<Iprops,Istate>{
    //@ts-ignore
    public audioCtx = new(window.AudioContext || window.webkitAudioContext)();
    public videoRef:React.RefObject<HTMLVideoElement> | undefined
    public analyser :AnalyserNode 

    public bufferLength:number

    public dataArray :Float32Array
 
    constructor(props:Iprops){
        super(props)
        this.state={
            mediaStream:null
        }
        this.videoRef= React.createRef();
    }

    componentDidMount(){
        setInterval(()=>{
            console.log(this.state.mediaStream?.active)
            if(!this.state.mediaStream?.active){
                this.setState({mediaStream:null})
            }
        },500)
    }
    
    startAnalyzing=()=>{
        this.analyser = this.audioCtx.createAnalyser();
        this.bufferLength = this.analyser.fftSize;
        this.dataArray = new Float32Array(this.bufferLength);
        
        //as small as possible
        this.analyser.fftSize = 32;
        this.analyser.smoothingTimeConstant = 1;
        //pick up very quiet sound
        this.analyser.minDecibels = -200;

        this.analyser.maxDecibels = -199;
    
        //@ts-ignore
        navigator.mediaDevices.getDisplayMedia({video:true,audio:true}).then((stream: MediaStream) => {
        if (stream.getAudioTracks().length > 0 ) {
            
           
                if(this.videoRef?.current!==null&&this.videoRef?.current!==undefined){
                    this.videoRef.current.srcObject = stream
                    console.log(stream)
                }else{
                    console.log("video error!")
                }
                
    
                
      
            var source = this.audioCtx.createMediaStreamSource(stream);
            source.connect(this.analyser);
            this.setState({mediaStream:stream})
            
        } else {

            console.log('Failed to get audiostream. Audio not shared or browser not supported')
            
        }
        }).catch((err: any) => console.log("Unable to open capture: ", err));
        this.audioCtx.resume();
    }


    render(){
        return(
            <div>
    
                {this.state.mediaStream==null ?
                    <div>
                        <p>Klik op 'start Analyzing', daarna klik je linksonder op audio delen en selecteer je daarna het <strong>tabblad</strong> of bureaublad die je in de gaten wil houden.</p>
                        <button onClick={this.startAnalyzing}>Start Analyzing</button>
                    </div>
                    :
                    <div>
                        <AudioChecker videoElement={this.videoRef} analyzer={this.analyser}/>
                    </div>
            
                }
                <video ref={this.videoRef} width="100%" autoPlay={true} muted={true}></video>
            </div>
        )
    }
}
export default Main