import * as React from 'react'

interface Iprops{
    analyzer:AnalyserNode
    videoElement:React.RefObject<HTMLVideoElement>|undefined
}

interface Istate{
    audioPlaying:boolean
    sendMessage:boolean
    sendAfter:number
    secondsSilence:number
    takeScreenshot:boolean
    naam:string
}

class AudioChecker extends React.Component<Iprops,Istate>{
    private  bufferLength:number | undefined ;
    private dataArray : Float32Array | undefined;
    private canvasRef : React.RefObject<HTMLCanvasElement>


    constructor(props:Iprops){
        super(props)
        this.state={
            audioPlaying:true,
            sendMessage:false,
            sendAfter:20,
            secondsSilence:0,
            takeScreenshot:false,
            naam: ""
        }
        this.canvasRef=React.createRef();
    }

    handleInputChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState<never>({
          [name]: value
        });
        this.setState({secondsSilence:0})
    }

    componentDidMount(){
        this.bufferLength=this.props.analyzer.fftSize
        this.dataArray=new Float32Array(this.bufferLength);

        setInterval(()=>{
            var geluid: boolean =this.isAudioPlaying()
            if(!geluid){
                var secondsSilence=this.state.secondsSilence+1
                if(secondsSilence==this.state.sendAfter){
                    if(this.state.sendMessage){
                        if(this.state.takeScreenshot){
                            this.sendScreenshot(secondsSilence)
                        }else{
                            this.sendSecondsMessage(secondsSilence)
                        }
                        
                    }
                   
                    
                }
                this.setState({secondsSilence:secondsSilence,audioPlaying:false})
            }else{
                this.setState({secondsSilence:0,audioPlaying:true})
            }
        },1000)
        
    }

    getScreenShot(){
        var context=this.canvasRef.current?.getContext("2d")
        console.log(this.props.videoElement.current.clientWidth)
        var setWidth=1280
        this.canvasRef.current.width=setWidth
        this.canvasRef.current.height=this.props.videoElement.current.clientHeight*setWidth/this.props.videoElement.current.clientWidth
        context?.drawImage(this.props.videoElement?.current,0,0,this.canvasRef.current.width,this.canvasRef.current.height)
        return this.canvasRef.current?.toDataURL()
    }

    sendScreenshot(seconds:number){
        console.log("send message")
        var screenshot=this.getScreenShot()
        console.log(screenshot)
        var split=screenshot?.split(",")[1]
        console.log("split")
        this.sendCustomMessage({message:"Het programma is "+seconds.toString()+" seconden stil geweest"},split)
    }

    sendSecondsMessage(seconds:number){
        this.sendCustomMessage({message:"Het programma is "+seconds.toString()+" seconden stil geweest"})
    }

    sendTextmessage(text:string){
        this.sendCustomMessage({message:text})
    }
    sendCustomMessage(paramsObject:any,body?:any){
        console.log(body)
        paramsObject["name"]=this.state.naam
        var url = new URL("https://script.google.com/macros/s/AKfycbyo7d62rdO35X_GJD76vhbQbgCyGSE38wUHrfR3pA/exec")
        url.search=new URLSearchParams(paramsObject).toString()
        fetch(url.toString(), {
            method:"POST",
            mode:"no-cors",
            body:body
        })
    }

    

    isAudioPlaying = () => {
        if(this.dataArray == undefined || this.bufferLength == undefined){
            return false
        }
        this.props.analyzer.getFloatTimeDomainData(this.dataArray);
        console.log(this.bufferLength)
        for (var i = 0; i < this.bufferLength; i++) {
            console.log(Math.abs(this.dataArray[i]))
            if (Math.abs(this.dataArray[i])>0.00005) return true;
        }
        return false;
    }

    render(){
        return(<div >
            <p style={{color:this.state.audioPlaying? "Green" : "Red"}}>{this.state.audioPlaying ? "Sound Playing" : "No Sound playing"}</p>
            <p>Seconds Silent: {this.state.secondsSilence}</p>
            <p>Code: <input type="text" onChange={this.handleInputChange} name="naam" value={this.state.naam}></input></p>
            <p>Send Message <input  onChange={this.handleInputChange} type="checkbox" name="sendMessage" checked={this.state.sendMessage} /></p>
            <p>Send Message after <input onChange={this.handleInputChange} name="sendAfter" type="number" value={this.state.sendAfter}/> seconds</p>
            <p>Take Screenshot: <input onChange={this.handleInputChange} type="checkbox" name="takeScreenshot" checked={this.state.takeScreenshot} /> (Let op dit vertraagd de melding)</p>
            <p><button onClick={()=>{this.sendTextmessage("Dit is een testbericht")}}>Send test message</button></p>
            <canvas ref={this.canvasRef} width="100%"></canvas>
        </div>)
    }

}
export default AudioChecker